import React from 'react'

// Components
import Layout from '../../components/layout.js'
import SEO from '../../components/seo.js'

// SVGs

export default () => (
    <Layout className="project project__coal">
        <SEO title="Beautiful Clean Coal" />
        <section className="hero position-relative">
            <img
                className="hero-img"
                src="https://d2jotzgct8q460.cloudfront.net/imgs/projects/beautiful-clean-coal/coal_1.png"
                alt="Beautiful Clean Coal Hero"
            />
            <div className="hero-content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="margin-bottom-4">
                                <span className="highlight__pollution">
                                    Beautiful Clean Coal
                                </span>
                            </h1>
                            <h3>
                                <span className="highlight__pollution">
                                    WPI
                                </span>
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="padding-y-10 bg-pollution-3">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 align-self-center">
                        <div className="side-by-side-divider">
                            <div className="left-content margin-bottom-3">
                                <h3 className="margin-bottom-3">
                                    I developed a data-driven narrative about
                                    the United State's energy production over
                                    the past 60 years.
                                </h3>
                                <p>
                                    For our data-visualization course at
                                    Worcester PolyTechnic Institute, my friends
                                    and I determined that we wanted to go beyond
                                    making a fancy dashboard and present a
                                    unique project narrating learnings revealed
                                    by our dataset. We decided we would make our
                                    project relevant to current events, ensure
                                    it would have beautiful interactive charts,
                                    and finally inform users to make an unbiased
                                    decision about a topic. We drew many of our
                                    inspirations from{' '}
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.pudding.cool"
                                    >
                                        <span className="underline-hover underline-hover__pudding-cool">
                                            www.pudding.cool
                                        </span>
                                    </a>
                                    .
                                </p>
                            </div>
                            <div className="divider margin-x-10"></div>
                            <div className="right-content">
                                <div className="margin-bottom-3">
                                    <h4>Timeline</h4>
                                    <p>1.5 Week Build</p>
                                </div>
                                <div className="margin-bottom-3">
                                    <h4 className="">My Role</h4>
                                    <p>Front End Engineer</p>
                                </div>
                                <div className="margin-bottom-3">
                                    <h4 className="margin-bottom-1">
                                        <b>Tools</b>
                                    </h4>
                                    <div className="tag-container">
                                        <div className="tag margin-bottom-1 margin-right-1">
                                            <div className="content">
                                                React JS
                                            </div>
                                        </div>
                                        <div className="tag margin-bottom-1 margin-right-1">
                                            <div className="content">
                                                Semiotic
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="margin-bottom-3">
                                    <h4 className="">
                                        <b>Team</b>
                                    </h4>
                                    <p>Derek Feehrer</p>
                                    <p>Michael Moukarzel</p>
                                </div>
                                <div className="">
                                    <h4 className="">
                                        <b>Themes</b>
                                    </h4>
                                    <p>
                                        Data-Visualization | Data Driven
                                        Narrative
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="padding-y-10 bg-grey-1">
            <div className="container">
                <div className="row margin-bottom-5">
                    <div className="col-md-12">
                        <h2 className="margin-bottom-3">The Problem</h2>
                        <p>
                            My team and I were given an assignment to create a
                            set of data visualizations as the final project for
                            our data visualization course. We were tasked with
                            choosing a dataset and then using any
                            framework/library of our choice to create 3+
                            visualizations.
                        </p>
                    </div>
                </div>
                <div className="row margin-bottom-5">
                    <div className="col-md-8">
                        <h2 className="margin-bottom-3">The Solution</h2>
                        <p>
                            My team and I decided to draw upon storytelling to
                            create a data driven narrative about energy
                            production in the United States over the past 60
                            years. We centralizing our insights around the "War
                            on Coal" and asked the question of whether or not
                            coal can trully be clean and how it's history has
                            compared to other forms of energy production in the
                            United States.
                        </p>
                    </div>
                    <div className="col-md-4">
                        <h2 className="margin-bottom-3">Goals</h2>
                        <ul className="inline-padding-none">
                            <li className="margin-bottom-2">
                                <p>
                                    Pick a relevant topic to tell a story about
                                </p>
                            </li>
                            <li className="margin-bottom-2">
                                <p>Choose the right tools for the job</p>
                            </li>
                            <li className="margin-bottom-2">
                                <p>
                                    Provide the readers with data to develop
                                    their own opinions
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="margin-bottom-3">My Role</h2>
                        <p>
                            My primary role on the 3 person team was creating
                            the interactive visualizations for the user to
                            explore the topic. We had 1 other frontend engineer
                            focusing on the user experience and another engineer
                            working on data munging<sup>[1]</sup>.
                        </p>
                        <br />
                        <p className="foot-note">
                            <i>
                                1. Munging - "Data wrangling, sometimes referred
                                to as data munging, is the process of
                                transforming and mapping data from one 'raw'
                                data form into another format with the intent of
                                making it more appropriate and valuable for a
                                variety of downstream purposes such as
                                analytics."
                            </i>
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <section className="padding-y-10 overflow-hidden">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row margin-bottom-5">
                            <div className="col-md-12 margin-bottom-5">
                                <h2 className="">Development Process</h2>
                            </div>

                            <div className="col-md-12">
                                <h3 className="margin-bottom-3">
                                    01. - Choosing a Topic and Dataset
                                </h3>
                                <p className="">
                                    For this project we knew that it would be
                                    sufficent enough to make a dashboard with
                                    some fancy interactive elements, however we
                                    wanted to change it up a little and use this
                                    assignment as an opportunity to educate
                                    readers on a topic. Therefore, we agreed to
                                    focus our final project around a current
                                    event and brainstormed several possible
                                    topics we could write about. One of the most
                                    important criteria for the topic was the
                                    amount of available data for it. After 2
                                    days of deliberation, knowing we only had
                                    about a week and a half to complete the
                                    project, we determined that our project
                                    would be focused around coal's role in
                                    energy production in the United States. One
                                    of the deciding factors was the ample amount
                                    resources about the US's energy production
                                    located at the{' '}
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.eia.gov/"
                                        className="underline-hover underline-hover__pollution"
                                    >
                                        U.S. Energy Information Administration
                                        (EIA)
                                    </a>
                                    .
                                </p>
                            </div>
                        </div>
                        <div className="row margin-bottom-5">
                            <div className="col-md-12">
                                <h3 className="margin-bottom-3">
                                    02. - What the Data Told Us?
                                </h3>
                                <p className="">
                                    There was lots of data to go through, so we
                                    decided that we had to narrow our scope to
                                    specific details about energy production. We
                                    determined that we would use 2 datasets, one
                                    highlighting how coal was used compared to
                                    other sources of energy and the other to
                                    determine where each energy producing
                                    factory was located and how much energy they
                                    produced. This would give us a historical
                                    view of how coal was used and the trends
                                    production for energy generating facilities.
                                    After our initial analysis, it was clear
                                    that the United States saw an early boom in
                                    coal energy production during the early 20th
                                    century. However, as the 20th century
                                    progressed there was a larger and larger
                                    shift towards renewable energy and
                                    eventually to natural gas.
                                </p>
                            </div>
                        </div>
                        <div className="row margin-bottom-5">
                            <div className="col-md-12">
                                <h3 className="margin-bottom-3">
                                    03. - Design It &amp; Determine the Data
                                    Visualizations
                                </h3>
                                <p className="margin-bottom-5">
                                    To narrate the history of energy production
                                    we brainstormed several potential
                                    visualization ideas. These are the designs
                                    we decide to move forward with:
                                </p>
                                <div className="row number-list">
                                    <div className="col-md-12 number-item margin-bottom-3">
                                        <p>
                                            Use a stacked area chart to display
                                            the 60 years of data production
                                            available to us and annotate
                                            important key events that changed
                                            how energy was produced moving
                                            forward.
                                        </p>
                                    </div>
                                    <div className="col-md-12 number-item margin-bottom-3">
                                        <p>
                                            A map of the United States that
                                            marks the locations of factories
                                            while coloring and scaling those
                                            markers depending on on the type of
                                            factory producing energy and how
                                            much energy it produces for a
                                            specific year. In addition, this map
                                            would be connected to a line graph
                                            showing energy production totals as
                                            a sum of the energy produced by
                                            factories accross a date range.
                                        </p>
                                    </div>
                                    <div className="col-md-12 number-item margin-bottom-3">
                                        <p>
                                            A line graph displaying coal miner
                                            employemnt rates across the past 60
                                            years.
                                        </p>
                                    </div>
                                    <div className="col-md-12 number-item margin-bottom-3">
                                        <p>
                                            The different ways in which you can
                                            burn coal in a factory to create
                                            electricity and how that compares to
                                            pollution from other energy sources.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row margin-bottom-5">
                            <div className="col-md-12">
                                <h3 className="margin-bottom-3">
                                    04. - Develop It
                                </h3>
                                <p className="">
                                    With our data and designs ready there was
                                    only one thing left to do, develop the
                                    webpage. To do this we had to break down the
                                    expreience into 3 main components the data,
                                    the user experience for the page, and the
                                    more specfic user expeerience for each data
                                    visualization. First, the data was in an
                                    unruly format and we needed to isolate it
                                    and put it into a format better suited for
                                    our graphs. Next, we needed to create the
                                    user experience each of the supporting
                                    visualizations. To create the each graph we
                                    used Semiotic, a data-visualization library
                                    created by Elijah Meeks. Finally, we needed
                                    to combine everything into a seemless
                                    experience for readers.
                                </p>
                            </div>
                        </div>
                        <div className="row margin-bottom-5">
                            <div className="col-md-12">
                                <h3 className="margin-bottom-3">
                                    05. - Present It
                                </h3>
                                <p className="margin-bottom-5">
                                    Within in couple days we had the project
                                    completed and launched it on Github pages!
                                    Feel free to check it out!
                                </p>

                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://mamoukar.github.io/DataVisFinal/index.html"
                                    className="primary-button primary-button__red hover"
                                >
                                    Check it Out!
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Layout>
)
